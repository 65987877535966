<template>
  <div class="switch-container">
    <label class="md_switch">
      <span class="label">{{ label }}</span>
      <input type="checkbox" v-model="value"/>
      <span class="md_switch__toggle"></span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',
    props: {
      label: {
        type: String,
        default: "Nur kostenlose Angebote",
      },
      fieldTypeId: {
        default: null,
      }
    },
    data() {
      return {
        value: false,
      }
    },
    watch: {
      value(value) {
        this.$emit('sliderChanged', this.fieldTypeId, value);
      }
    },
    methods: {
    },
  }
</script>
<style lang="scss" scoped>

  .switch-container {
    margin-bottom: 20px;
  }

  .label {
    margin-right: 110px;
  }

  body {
    background: #F5F5F5;
  }

  .md_switch {
    display: inline-flex;
    font-family: "Open Sans";
    align-items: center;
    margin: 5px 0;
  }

  .md_switch .md_switch__toggle {
    position: relative;
    cursor: pointer;
  }

  .md_switch [type=checkbox] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

/* default states */

.md_switch .md_switch__toggle::before,
.md_switch .md_switch__toggle::after {
  content: '';
  display: block;
  margin: 0 3px;
  transition: all 100ms cubic-bezier(0.4, 0.0, 0.2, 1);
  box-shadow: 1px 1px 1px grey;
  background: #ddd;
}

.md_switch .md_switch__toggle::before {
  height: 0.95em;
  width: 3em;
  border-radius: 0.65em;
  opacity: 0.45;
}

.md_switch .md_switch__toggle::after {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 1.7em;
  width: 1.7em;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0,0,0,0.2), 0 0 2px rgba(0,0,0,0.4);
}

/* special states */
.md_switch [type=checkbox]:focus+.md_switch__toggle {
  outline: #5d9dd5 solid 1px;
  box-shadow: 0 0 8px #5e9ed6;
}

.md_switch [type=checkbox]:disabled+.md_switch__toggle {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.6;
}

.md_switch [type=checkbox]:disabled+.md_switch__toggle::after {
  box-shadow: none;
}

/* checked states */

.md_switch [type=checkbox]:checked+.md_switch__toggle::before,
.md_switch [type=checkbox]:checked+.md_switch__toggle::after{
  background: #2E55A5;
}

.md_switch [type=checkbox]:checked+.md_switch__toggle::after {
  transform: translate(calc(3em - 100%), -50%);
}

</style> 